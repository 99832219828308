<template>
  <div>
    <input
      type="text"
      class="form-control data-text"
      v-model="searchInput"
      @input="onInputChange"
      placeholder="Search for a place"
    />
    <b-list-group v-if="predictions.length" class="mb-1">
      <b-list-group-item v-for="(prediction, index) in predictions" :key="index">
        <a @click="selectPlace(prediction)">{{ prediction.description }}</a>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    address: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchInput: "",
      predictions: [],
      selectedPlace: null,
    };
  },
  watch: {
    address: {
      immediate: true,
      handler() {
        this.setSearchInput();
      },
    },
  },
  // created() {
  //   if (this.address) {
  //     this.searchInput = this.address;
  //   }
  // },
  methods: {
    setSearchInput() {
      this.searchInput = this.address;
    },
    onInputChange() {
      if (!this.searchInput) {
        this.predictions = [];
        return;
      }

      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: this.searchInput,
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.predictions = predictions;
          } else {
            this.predictions = [];
          }
        }
      );
    },
    selectPlace(prediction) {
      const place_id = prediction.place_id;
      const placesService = new google.maps.places.PlacesService(
        document.createElement("div")
      );
      placesService.getDetails(
        {
          placeId: place_id,
          fields: ["geometry"],
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const location = place.geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            this.searchInput = prediction.description;
            const address = this.searchInput;
            this.$emit("placeSelected", { lat, lng, address });
            this.predictions = [];
          }
        }
      );
    },
  },
};
</script>

<style scoped>
/* Add your component's styles here */
</style>
