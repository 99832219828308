<template>
  <div class="fluid" id="locations">
    <b-card style="width: 80%; margin: 0 auto; margin-bottom: 20px">
      <div class="row d-flex align-items-center">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <label for="" class="label-font">Search:</label>
              <b-form-group>
                <b-form-input
                  v-debounce:300ms="getLocations"
                  v-model="query.name"
                  type="text"
                  placeholder="Search"
                  class="search-input bg-light"
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="col-md-3 p-0">
          <b-button
            v-if="$can('create', 'Location')"
            v-b-modal.modal-prevent-closing
            class="btn mt-1 mt-lg-0 add-btn d-block ml-auto"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add New Location </span>
              <span>
                <img
                  src="@/assets/images/icons/add-icon.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
    <div
      class="table-responsive bg-white cursor-icon short-table rounded-lg shadow-lg p-2"
      style="width: 80%; margin: 0 auto"
    >
      <b-table
        :items="locations"
        :fields="parentFields"
        show-empty
        responsive
        striped
        hover
        :per-page="query.per_page"
      >
        <template #cell(id)="row">
          <div :class="`p-1`">
            <p>
              {{ row.index + from }}
            </p>
          </div>
        </template>
        <template #cell(name)="row">
          <p>
            {{ row.item.name }}
          </p>
        </template>
        <template #cell(action)="row">
          <div>
            <button
              v-if="$can('update', 'Location')"
              v-b-modal.modal-prevent-closing
              @click.prevent="getLocation(row.item.id)"
              class="btn btn-info d-block ml-auto"
            >
              Edit
            </button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="total > query.per_page"
        class="p-1 mt-1 d-flex justify-content-end bg-white"
        v-model="query.page"
        :total-rows="total"
        :per-page="query.per_page"
        aria-controls="my-table"
        @change="getLocations($event)"
        pills
        size="lg"
        page-class="info"
      ></b-pagination>
    </div>

    <b-modal
      size="lg"
      id="modal-prevent-closing"
      ref="modal"
      :title="`${location.id ? 'Update' : 'Create New'} Location`"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="location.id ? 'Update' : 'Save'"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- {{ location }} -->
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="location.name"
            :state="nameState"
            required
            class="mb-2 bg-light data-text"
            placeholder="Enter Location Name"
          ></b-form-input>
        </b-form-group>
        <location-search-component :address="address" @placeSelected="getSelectedPlace" />
        <GmapMap
          :center="position"
          :zoom="zoom"
          map-type-id="terrain"
          style="width: 500px; height: 300px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @dragend="getMarker($event)"
          />
        </GmapMap>
        <b-form-group
          label="Latitude"
          label-for="name-input"
          invalid-feedback="Latitude is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="location.lat"
            :state="nameState"
            required
            class="mb-2 bg-light data-text"
            placeholder="Enter Location Latitude"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Longitude"
          label-for="name-input"
          invalid-feedback="Longitude is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="location.long"
            :state="nameState"
            required
            class="mb-2 bg-light data-text"
            placeholder="Enter Location Longitude"
            disabled
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";

import vSelect from "vue-select";
import LocationSearchComponent from "./Components/LocationSearchComponent.vue";
export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    LocationSearchComponent,
  },

  data() {
    return {
      homeLink: false,
      locations: [],
      nameState: null,
      zoom: 15,
      location: {
        name: "",
        lat: "",
        long: "",
        status: 0,
      },
      address: "",
      total: 0,
      from: 0,
      markers: [],
      position: {
        lat: -33.809350294864466,
        lng: 151.16520377499998,
      },
      query: {
        name: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getLocations();
    this.getCurrentLocation();
  },
  mounted() {
    this.zoom = Number(this.$store.state.mapConfig.mapConfig.zoom);
  },
  methods: {
    getSelectedPlace(place) {
      this.location.lat = place.lat;
      this.location.long = place.lng;
      this.location.address = place.address;
      this.position = {
        lat: place.lat,
        lng: place.lng,
      };
      this.markers = [
        {
          position: {
            lat: place.lat,
            lng: place.lng,
          },
        },
      ];
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geo Location not supported by browser");
      }
      let self = this;
      function showPosition(position) {
        const location = {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        };
        self.markers = [
          {
            position: {
              lat: location.latitude,
              lng: location.longitude,
            },
          },
        ];
        self.position = {
          lat: location.latitude,
          lng: location.longitude,
        };
      }
    },
    getMarker(event) {
      // get lat and lng from event
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      this.location.lat = lat;
      this.location.long = lng;
    },
    getLocations(e) {
      if (e && typeof e === "number") {
        this.query.page = e;
      }
      this.$store
        .dispatch("location/getLocations", this.query)
        .then((response) => {
          this.locations = response.data.data;
          this.total = response.data.total;
          this.from = response.data.from;
        });
    },
    async getLocation(id) {
      let response = await this.$store.dispatch("location/getLocation", id);
      this.location = response.data;
      this.address = this.location?.address;
      this.position = {
        lat: Number(response.data.lat),
        lng: Number(response.data.long),
      };
      this.markers = [
        {
          position: {
            lat: Number(response.data.lat),
            lng: Number(response.data.long),
          },
        },
      ];
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.nameState = null;
      this.location = {
        name: "",
        lat: this.position.lat,
        long: this.position.lng,
      };
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.location.id) {
        this.updateLocation();
      } else {
        this.createLocation();
      }
    },
    createLocation() {
      this.$store
        .dispatch("location/createLocation", this.location)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getLocations();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Location Create Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
    updateLocation() {
      this.$store
        .dispatch("location/updateLocation", this.location)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getLocations();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Location Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e !important;
}

.vue-map-container {
  width: 100% !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
